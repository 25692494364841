import React, { useState, useEffect } from 'react';
import { updateEmail, updateProfile, logUserOut } from '../../api/Firebase';
import CustomButton from '../../components/CustomButton';
import { userToken, systemMessage } from '../../State';
import { useSetRecoilState, useRecoilValue } from 'recoil';

const ChangeEmail = () => {

    const [username, setUsername] = useState('');
    const [confirm, setConfirm] = useState('');
    const [error, setError] = useState('');

    const user = useRecoilValue(userToken);
    const sysMessage = useSetRecoilState(systemMessage);

    const updateUsername = e => {
        setUsername(e.target.value);
        setError('');
    }

    const updateConfirm = e => {
        setConfirm(e.target.value);
        setError('');
    }

    const submit = () => {
        if (isEmpty()) {
            setError('Please fill all fields');
            return;
        }

        if (username !== confirm) {
            setError('Emails need to match!');
            return;
        }

        updateEmail(username, () => {
            sysMessage('Email Changed');
            updateProfile(user, { email: username });
            logUserOut();

        }, err => {
            setError(err);
        })
    }

    const isEmpty = () => {
        if (username.trim() === '') return true;

        return false;
    }

    return (
        <div className="login-container">
            <img src="images/logo.png" />

            <div className="login-card">
                <div className="login-card-header">
                    <h3>eDocket</h3>
                </div>

                <div className="login-card-main">
                    <label htmlFor="username">New Email</label>
                    <input id="username" type="email" placeholder="email@address.com" value={username} onChange={updateUsername} />
                    <label htmlFor="confirm" >Confirm New Email</label>
                    <input id="confirm" type="email" placeholder="email@address.com" value={confirm} onChange={updateConfirm} />
                    <CustomButton className="login-button" width="50%" onClick={submit}>Submit</CustomButton>
                </div>

                <div className="login-card-footer">
                    <p className="login-link" >Please confirm your new email address. Note you will need to login again.</p>
                    <p className="login-error">{error}</p>
                </div>
            </div>
        </div>
    );
}

export default ChangeEmail;