import React, { useState, useEffect } from 'react';
import "../css/NewDocket.css";
import CustomButton from '../components/CustomButton';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { userToken, userProfile, systemMessage } from '../State';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { capitalFirstLetterWholeWord, upperCase } from '../util/Strings';
import { addNewDocket, updateDocket } from '../api/Firebase';

const NewDocket = ({ visible, close, edit, paste }) => {

    const [user, setUser] = useRecoilState(userToken);
    const [profile, setProfile] = useRecoilState(userProfile);
    const setMessage = useSetRecoilState(systemMessage);

    const [driver, setDriver] = useState(profile ? profile.name : '');
    const [customer, setCustomer] = useState('');
    const [workingFor, setWorkingFor] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [rego, setRego] = useState('');
    const [truckType, setTruckType] = useState('');

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [travelTime, setTravelTime] = useState(null);

    const add = () => {
        //some input validation
        let validRego = upperCase(rego);
        let validCustomer = capitalFirstLetterWholeWord(customer);
        let validWorkingFor = capitalFirstLetterWholeWord(workingFor);

        let data = {
            driver: driver,
            customer: validCustomer,
            rego: validRego,
            truckType: truckType,
            startTime: startTime,
            endTime: endTime,
            travelTime: travelTime,
            workingFor: validWorkingFor,
            orderNo: orderNo
        }

        addNewDocket(user, profile, data).then(result => {
            onClose();
            setMessage('docket created successfully');
            //Navigate to specific docket
        });
    }

    const onClose = () => {
        resetInput();
        close();
    }

    const save = () => {
        //some input validation
        let validRego = upperCase(rego);
        let validCustomer = capitalFirstLetterWholeWord(customer);
        let validWorkingFor = capitalFirstLetterWholeWord(workingFor);

        let data = {
            driver: driver,
            customer: validCustomer,
            rego: validRego,
            truckType: truckType,
            startTime: startTime,
            endTime: endTime,
            travelTime: travelTime,
            workingFor: validWorkingFor,
            orderNo: orderNo
        }

        updateDocket(user, profile, edit.id, data).then(result => {
            onClose();
            setMessage('docket update successful');
        }).catch(error => {
            alert("Error: ", error.message);
            alert("Error: ", error);
            console.log("id: " + edit.id);
        });


    }

    useEffect(() => {
        if (edit) {
            setUpEdit();
        }
        if (paste) {
            setUpPaste();
        }
    }, [edit, paste]);



    const updateDriver = e => {
        setDriver(e.target.value);
    }
    const updateCustomer = e => {
        setCustomer(e.target.value);
    }
    const updateWorkingFor = e => {
        setWorkingFor(e.target.value);
    }
    const updateOrderNo = e => {
        setOrderNo(e.target.value);
    }
    const updateRego = e => {
        setRego(e.target.value);
    }
    const updateTruckType = e => {
        setTruckType(e.target.value);
    }

    const resetInput = () => {
        setCustomer('');
        setWorkingFor('');
        setOrderNo('');
        setRego('');
        setTruckType('');
    }

    const setUpEdit = () => {
        setDriver(edit.driver)
        setCustomer(edit.customer);
        setWorkingFor(edit.workingFor);
        setOrderNo(edit.orderNo);
        setRego(edit.rego);
        setTruckType(edit.truckType);
    }

    const setUpPaste = () => {
        setDriver(paste.driver)
        setCustomer(paste.customer);
        setWorkingFor(paste.workingFor);
        setOrderNo(paste.orderNo);
        setRego(paste.rego);
        setTruckType(paste.truckType);
    }


    return (
        <div className={visible ? "ND-container" : "ND-container hide"}>
            <div className="ND-card">
                <div className="ND-card-header">
                    {!edit ? <h4>Create Job</h4> : <h4>Edit Job</h4>}
                    <AiOutlineCloseCircle className="ND-icon" onClick={onClose} />
                </div>
                <div className="ND-card-body">
                    <label>Driver</label>
                    <input type="text" placeholder="Driver" value={driver} onChange={updateDriver} />
                    <label>Customer</label>
                    <input type="text" placeholder="Customer" value={customer} onChange={updateCustomer} />
                    <label>Working For</label>
                    <input type="text" placeholder="Working For" value={workingFor} onChange={updateWorkingFor} />
                    <label>Order No.</label>
                    <input type="text" placeholder="Order No." value={orderNo} onChange={updateOrderNo} />
                    <label>Rego</label>
                    <input type="text" placeholder="Rego" value={rego} onChange={updateRego} />
                    <label>Truck Type</label>
                    <input type="text" placeholder="Truck Type" value={truckType} onChange={updateTruckType} />
                </div>
                {!edit ? <CustomButton className="ND-btn" width="100%" onClick={add}>Add</CustomButton> : null}
                {edit ? <CustomButton className="ND-btn" width="100%" onClick={save}>Save</CustomButton> : null}
            </div>
        </div>
    );
}

export default NewDocket;