import React from 'react';
import '../css/ImageView.css';
import { FaTrash, FaArrowLeft } from 'react-icons/fa';

const ImageView = ({ visible, image, onDelete, onClose }) => {
    return (
        <div className={visible ? "iv-container" : "iv-container hidden"}>
            <div className="iv-card">
                {image && <img src={image} alt="viewable" />}
                <div className="iv-controls">
                    <FaArrowLeft onClick={onClose} />
                    <FaTrash onClick={onDelete} />
                </div>
            </div>
        </div>
    );
}

export default ImageView;