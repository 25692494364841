export function upperCase(text) {
    //console.log(text.toUpperCase());
    return text.toUpperCase();
}

function capitalizeFirstLetter(string) {
    //console.log(string.charAt(0).toUpperCase() + string.slice(1));
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalFirstLetterWholeWord(text) {
    var words = text.split(" ");
    var finalString = "";

    for (let i = 0; i < words.length; i++) {
        if (finalString === "") {
            finalString = capitalizeFirstLetter(words[i]);
        } else {
            finalString = finalString + " " + capitalizeFirstLetter(words[i]);
        }
    }

    //console.log(finalString);
    return finalString;
}