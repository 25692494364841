import React, { useState } from 'react';
import "../css/Timepicker.css";
import { AiOutlineClockCircle } from 'react-icons/ai';
import TimeWheel from '../components/TimeWheel';

const Timepicker = ({ value, onChange, label }) => {
    const [timeWheel, setTimeWheel] = useState(false);

    const toggleTimeWheel = () => {
        setTimeWheel(!timeWheel);
    }

    return (
        <div className="timepicker-container">
            <label>{label}</label>
            <div className="timepicker-input-area">
                <input type="text" readOnly placeholder="hh:mm" value={value} />
                <AiOutlineClockCircle onClick={toggleTimeWheel} />
            </div>

            <TimeWheel
                visible={timeWheel}
                onComplete={toggleTimeWheel}
                onChange={onChange}
            />

        </div>
    );
}

export default Timepicker;