import { compareTime, differenceInTimeStringfy } from './Times';
import { longCurrentDate } from './Dates.js';
import axios from 'axios';

function getEmailObject(joblist, job, profile, travelTime, serial) {
    let timeDiff = differenceInTimeStringfy(job.startTime, job.endTime);

    var tableItems = joblist.map((item) => {
        if (item.imageUri === undefined) {
            return `<tr>
            <td class="docket-item"><p>${item.material}</p></td>
            <td class="docket-item"><p>${item.docketNo}</p></td>
            <td class="docket-item"><p>${item.fromLocation}</p></td>
            <td class="docket-item"><p>${item.toLocation}</p></td>
            <td class="docket-item"><p>${item.quantity}</p></td>
            <td class="docket-item"><p>${item.pickupTime}</p></td>
            <td class="docket-item"><p>${item.dropOffTime}</p></td>
            <td class="docket-item"><p>${item.notes}</p></td>
        </tr>`;
        }
    });

    var tableString = "";
    for (let i = 0; i < tableItems.length; i++) {
        if (tableItems[i]) {
            tableString += tableItems[i];
        }
    }


    var imageArray = [];
    joblist.map(item => {
        if (item.imageUri !== undefined) {
            imageArray.push(item.imageUri);
        }
    });

    var email = {
        name: profile.name,
        email: profile.linemanager,
        driver: job.driver,
        customer: job.customer,
        workingFor: job.workingFor,
        orderNo: job.orderNo,
        rego: job.rego,
        truckType: job.truckType,
        startTime: job.startTime,
        endTime: job.endTime,
        travelTime: travelTime,
        timeDiff: timeDiff,
        longDate: longCurrentDate(),
        auth: "auth1234",
        serial: serial,
        tableItems: tableString,
        images: imageArray,
        company: profile.company
    }

    return email;
};

export function orderLoadsByPickupTime(list) {
    let imageArray = [];
    let loadArray = [];

    for (let i = 0; i < list.length; i++) {
        if (!list[i]) continue;

        if (list[i].imageUri) {
            imageArray.push(list[i]);
        } else {
            loadArray.push(list[i]);
        }
    }

    loadArray.sort((a, b) => {
        return compareTime(a.pickupTime, b.pickupTime);
    })

    if (imageArray.length > 0) {
        loadArray.push(imageArray[0]);
    }


    return loadArray;


}

export function sendEmail(joblist, job, profile, travelTime, serial, approvFunc) {
    //order the joblist by pickupTime
    let orderedList = orderLoadsByPickupTime(joblist);

    //the actual email content
    var email = getEmailObject(orderedList, job, profile, travelTime, serial);

    // console.log("emailobj: ", email);

    //send it
    axios.post('https://makeiteasy.co.nz/customreport/index.php', email)
        .then(response => {
            console.log("response: ", response);
            approvFunc(response.data);
        }).catch(error => {
            console.log(error);
        });

}

