import React, { useState, useEffect } from 'react';
import '../css/TravelTimeOverlay.css';
import CustomButton from '../components/CustomButton';
import CustomDropdown from '../components/CustomDropdown';

const TravelTimeOverlay = ({ visible, onClose, onSubmit }) => {

    const [hours, setHours] = useState([]);
    const [minutes, setMinutes] = useState([]);
    const [travelTime, setTravelTime] = useState(0);

    const [selectedHour, setSelectedHour] = useState(0);
    const [selectedMinute, setSelectedMinute] = useState(0);

    useEffect(() => {
        if (hours.length > 0) return;

        let h = [];
        let m = [];
        for (let i = 0; i < 13; i++) {
            h.push(i);
            m.push(i * 5);
        }

        setHours(h);
        setMinutes(m);
    });

    const hourChange = (t) => {
        setSelectedHour(parseInt(t));

        let time = (parseInt(t) * 60) + parseInt(selectedMinute);

        setTravelTime(time);
    }

    const minuteChange = (t) => {
        setSelectedMinute(parseInt(t));

        let time = (parseInt(selectedHour) * 60) + parseInt(t);

        setTravelTime(time);
    }

    const submit = () => {
        onClose();
        onSubmit(travelTime);
    }


    return (
        <div className={visible ? "tt-container" : "tt-container hidden"}>
            <div className="tt-card">
                <div className="tt-header">
                    <h4>Please Enter Travel Time</h4>
                </div>
                <div className="tt-body">
                    <CustomDropdown
                        className="tt-dropdown"
                        defaultValue={0}
                        options={hours}
                        id="hour"
                        labelTitle="Hours"
                        onChange={hourChange}
                    />
                    <CustomDropdown
                        className="tt-dropdown"
                        defaultValue={0}
                        options={minutes}
                        id="minute"
                        labelTitle="Minutes"
                        onChange={minuteChange}
                    />
                </div>
                <div className="tt-footer">
                    <CustomButton className="tt-button" onClick={submit}>Submit</CustomButton>
                    <CustomButton className="tt-button" onClick={onClose}>Cancel</CustomButton>
                </div>

            </div>
        </div>
    );
}

export default TravelTimeOverlay;