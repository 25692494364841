import React, { useState } from 'react';
import CustomButton from '../../components/CustomButton';
import { useSetRecoilState } from 'recoil';
import { systemMessage } from '../../State';
import { sendResetPassEmail } from '../../api/Firebase';
import { useHistory } from 'react-router-dom';


const ChangePassword = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [prompt, setPrompt] = useState('Please verify your email and we will send you a reset link.');

    const sysMessage = useSetRecoilState(systemMessage);

    let history = useHistory();

    const updateUsername = e => {
        setUsername(e.target.value);
        setError('');
    }

    const sendLink = () => {
        if (username.trim() === '') {
            setError('Please fill all fields');
            return;
        }

        sendResetPassEmail(username, () => {
            sysMessage('Email Sent');
            setPrompt('A verification email has been sent. Please check your inbox and junk mail.');
        }, (err) => {
            setError(error);
        })
    }

    let style = {
        display: 'flex',
        justifyContent: 'space-evenly',
    }

    return (
        <div className="login-container">
            <img src="images/logo.png" />

            <div className="login-card">
                <div className="login-card-header">
                    <h3>eDocket</h3>
                </div>

                <div className="login-card-main">
                    <label htmlFor="username">Email</label>
                    <input id="username" type="email" placeholder="email@address.com" value={username} onChange={updateUsername} />
                    <div style={style}>
                        <CustomButton className="login-button" width="45%" onClick={() => history.goBack()}>Back</CustomButton>
                        <CustomButton className="login-button" width="45%" onClick={sendLink}>Submit</CustomButton>
                    </div>

                </div>

                <div className="login-card-footer" >
                    <p className="login-link" >{prompt}</p>
                    <p className="login-error">{error}</p>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;