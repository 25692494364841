// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";


// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
    apiKey: "AIzaSyB-nmoUQ5OjokhNKDwtpzBotm5Suo2dM5k",
    authDomain: "rn-rocknrubble-app-3a88a.firebaseapp.com",
    databaseURL: "https://rn-rocknrubble-app-3a88a.firebaseio.com",
    projectId: "rn-rocknrubble-app-3a88a",
    storageBucket: "rn-rocknrubble-app-3a88a.appspot.com"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);




export function createUser(email, password, success, fail) {
    firebase.auth().createUserWithEmailAndPassword(email, password).then(cred => {
        //  console.log("debug check point 1");
        // user created successfully
        success(cred);

    }).catch(error => {
        fail(error);
    });
}

export function createProfile(data, userID, redirect) {
    //get company listing info
    var docRef = firebase.firestore().collection("private_globals").doc("companies");

    docRef.get().then(doc => {
        if (doc.exists) {

            let list = doc.data().list;
            let company;
            let manager;

            for (let i = 0; i < list.length; i++) {

                if (list[i].code == data.company) {
                    company = list[i].name;
                    manager = list[i].linemanager;
                }
            }

            //create the actual profile
            firebase
                .firestore()
                .collection("users")
                .doc(userID)
                .set({
                    name: data.name,
                    email: data.email,
                    companyCode: data.company,
                    company: company,
                    linemanager: manager,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    numberofdockets: 0
                });

            redirect();


        } else {
            console.log("Error: could find private_globals");
        }
    }).catch(error => {
        console.log("Error: fetching private_globals");
    });



}

export function IsLoggedIn(loginFnc, logoutFnc) {

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            loginFnc(user);
        } else {
            logoutFnc();
        }
    });
}

export function logUserIn(email, password, fail) {

    firebase.auth().signInWithEmailAndPassword(email, password)
        .then((user) => {
            // Signed in 
        })
        .catch((error) => {
            fail(error);
        });
}

export function logUserOut() {
    firebase.auth().signOut().then(() => {
        // Sign-out successful.
    }).catch((error) => {
        console.log("failed to sign user out: ", error);
    });
}



export function getProfile(uid, pass, fail) {
    var ref = firebase.firestore().collection('users').doc(uid);

    ref.get().then(doc => {
        if (doc.exists) {
            pass(doc.data());
        } else {
            fail("No document found");
        }
    }).catch(error => {
        fail("Error getting document: " + error.code);
    });
}

export function getDB() {
    return firebase.firestore();
}

export function addNewDocket(user, profile, data) {
    let db = firebase.firestore();

    let db_name = '';

    if (profile.name.length >= 3) {
        db_name = `dockets: ${profile.name.substring(0, 3)}:${user.uid.substring(0, 4)}`;
    } else {
        db_name = `dockets: ${profile.name}:${user.uid.substring(0, 4)}`;
    }

    return db.collection(db_name)
        .add({
            ...data,
            submitted: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });
}

export function addNewLoad(user, profile, docID, data) {
    var loadCollection = getLoadCollection(user, profile, docID);

    return loadCollection.add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })

}

export function getDocketCollection(user, profile) {
    let db = firebase.firestore();

    let db_name = '';

    if (profile.name.length >= 3) {
        db_name = `dockets: ${profile.name.substring(0, 3)}:${user.uid.substring(0, 4)}`;
    } else {
        db_name = `dockets: ${profile.name}:${user.uid.substring(0, 4)}`;
    }

    return db.collection(db_name);
}

export function getDocket(user, profile, docID) {
    return getDocketCollection(user, profile).doc(docID);
}

export function convertToDate(timestamp) {
    return timestamp.toDate();
}

export function updateDocket(user, profile, docID, data) {
    var ref = getDocketCollection(user, profile);

    return ref.doc(docID).update(data);
}

export function updateLoad(user, profile, docID, loadID, data) {
    var ref = getLoadCollection(user, profile, loadID);

    // console.log("load collection id: ", docID);
    // console.log("load id:", loadID);

    return ref.doc(docID).update(data);
}

export function updateLoadImages(user, profile, loadID, url, success) {
    var ref = getLoadCollection(user, profile, loadID).doc('z_images');

    ref.get().then(docData => {
        if (docData.exists) {
            //update image array
            ref.update({ imageUri: firebase.firestore.FieldValue.arrayUnion(url) });
            console.log("Image update successful");
            success();
        } else {
            ref.set({
                imageUri: [url],
                createdAt: firebase.firestore.FieldValue.serverTimestamp()
            });
            console.log("Image upload successful");
            success();
        }
    }).catch(error => {
        console.log("Image Upload error: ", error);
    });
}

//Remove an 'image' document under a jobs sub collection
export function removeLoadImage(user, profile, loadID, url, success) {
    const ref = getLoadCollection(user, profile, loadID).doc("z_images");

    ref.get().then(docData => {
        if (docData.exists) {
            //update image array
            ref.update({ imageUri: firebase.firestore.FieldValue.arrayRemove(url) });
            success();
            console.log("Image removal successful");
        }
    }).catch(error => {
        console.log("Image remove error: ", error);
    });
}



export function getLoadCollection(user, profile, docID) {
    let collectionID = '';

    if (profile.name.length >= 3) {
        collectionID = `jobs: ${profile.name.substring(0, 3)}:${docID.substring(0, 4)}`;
    } else {
        collectionID = `jobs: ${profile.name}:${docID.substring(0, 4)}`;
    }

    // console.log("Fetching: ", collectionID);

    return getDocket(user, profile, docID).collection(collectionID);
}

export function getStorageRef() {
    return firebase.storage().ref();
}

//get a specific companys prefix and docketcount
export function getCompanyData(companyCode, passCallback, failCallback) {
    var docRef = firebase.firestore().collection("private_globals").doc(companyCode);

    docRef.get().then(doc => {
        if (doc.exists) {
            passCallback(doc.data());
        } else {
            failCallback("Company not found");
        }
    }).catch(error => {
        var s = 'Error fetching company data: \n';
        failCallback(s + error.toString());
    });
}

//update the data of specific company
export function updateCompanyData(companyCode, data) {
    var item = firebase.firestore().collection("private_globals").doc(companyCode).update(data);
    return item;
}

export function getIncrement() {
    return firebase.firestore.FieldValue.increment(1);
}

export function updateEmail(email, passCallback, failCallback) {
    var user = firebase.auth().currentUser;

    user.updateEmail(email).then(function () {
        passCallback();
    }).catch(function (error) {
        failCallback(error.toString());
    });
}

export function updateProfile(user, data) {
    var item = firebase.firestore().collection("users").doc(user.uid).update(data);
    return item;
}

export function sendResetPassEmail(email, msgSent, msgFail) {
    var auth = firebase.auth();

    auth.sendPasswordResetEmail(email).then(function () {
        msgSent();
    }).catch(function (error) {
        msgFail(error.toString());
    });
}
