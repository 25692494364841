function timeToObject(stringTime) {
    let array = stringTime.split(':');

    const timeObject = {
        hour: parseInt(array[0]),
        minute: parseInt(array[1].split(' ')[0]),
        after: array[1].split(' ')[1]
    };

    return timeObject
}

function getMinuteTime(timeObject) {
    let m = 0;
    let hour = timeObject.hour % 12;

    if (timeObject.after === 'pm' || timeObject.after === 'PM' || timeObject.after === 'p.m.') {
        hour += 12;
    }

    m += (hour * 60) + timeObject.minute;
    return m;
}

export function differenceInTimeStringfy(timeA, timeB) {
    if (timeA === undefined || timeB === undefined) {
        if (timeA !== undefined) console.log("timeA: ", timeA);
        if (timeB !== undefined) console.log("timeB: ", timeB);
        return "undefined";
    }
    if (timeA.trim() === "" || timeB.trim() === "") return "undefined";

    let a = timeToObject(timeA);
    let b = timeToObject(timeB);

    let inta = getMinuteTime(a);
    let intb = getMinuteTime(b);




    let result = intb - inta;

    let hours = Math.floor(result / 60);
    let minutes = result % 60;

    let roundedMinutes;

    if ((minutes % 15) <= 6) {
        roundedMinutes = minutes - (minutes % 15);
    } else {
        roundedMinutes = minutes + (15 - (minutes % 15));
    }


    return `${hours} hour(s) ${roundedMinutes} minute(s)`;
}

export function compareTime(timeA, timeB) {
    if (timeA === undefined || timeB === undefined) {
        if (timeA !== undefined) console.log("timeA: ", timeA);
        if (timeB !== undefined) console.log("timeB: ", timeB);
        throw new Error("Undefined Time");
    }
    if (timeA.trim() === "" || timeB.trim() === "") throw new Error('Invalid Time format');

    let a = timeToObject(timeA);
    let b = timeToObject(timeB);

    // console.log("timeobj a:", a);
    // console.log("timeobj b:", b);

    let inta = getMinuteTime(a);
    let intb = getMinuteTime(b);

    // console.log("minute a:", inta);
    // console.log("minute b:", intb);

    if (inta < intb) {
        return -1;
    } else if (inta > intb) {
        return 1;
    } else {
        return 0;
    }
}

export function convert24time(initialtime) {
    if (initialtime === undefined || initialtime === "") return;
    var arr = initialtime.split(":");
    var hh = arr[0];
    arr = arr[1].split(" ");
    const mm = arr[0];
    const a = arr[1];

    let inthours = parseInt(hh);
    if (a === "PM" || a === "pm" || a === "p.m") {
        inthours = (inthours + 12) % 24
    }

    hh = inthours < 10 ? "0" + inthours : "" + inthours;

    // return hh + ":" + mm + ":" + "00";
    return `${hh}:${mm}:00`;
}