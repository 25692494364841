import React, { useState, useEffect } from 'react';
import "../css/Review.css";
import { userToken, userProfile } from '../State';
import { useRecoilState } from 'recoil';
import { getDocketCollection } from '../api/Firebase';
import { useHistory } from 'react-router-dom';
import JobItem from '../components/JobItem';
import Dropdown from '../components/Dropdown';
import NewDocket from '../components/NewDocket';

const Review = () => {
    const [user, setUser] = useRecoilState(userToken);
    const [profile, setProfile] = useRecoilState(userProfile);
    const [jobList, setJobList] = useState([]);

    const [fileterOptions, setFilterOptions] = useState(["Date", "Driver", "Customer", "Rego", "Submitted"]);
    const [selectedFilter, setSelectedFilter] = useState("Date");

    const [edit, setEdit] = useState();
    const [paste, setPaste] = useState();

    const [addOverlay, setAddOverlay] = useState(false);

    let history = useHistory();

    const onEdit = data => {
        setEdit(data);
        toggleAddOverlay();
    }

    const onPaste = data => {
        setPaste(data);
        toggleAddOverlay();
    }

    const onDelete = (id) => {
        let result = window.confirm("Are you sure you wish to delete docket: " + id + " ? This cannot be undone!");

        if (result) {
            let ref = getDocketCollection(user, profile).doc(id).delete();
            return ref;
        }

    }

    const toggleAddOverlay = () => {
        if (addOverlay) setEdit(null);
        if (addOverlay) setPaste(null);
        setAddOverlay(!addOverlay);
    }

    const getOrderItem = (sort) => {
        switch (sort) {
            case "Date":
                return "createdAt";
            case "Driver":
                return "driver";
            case "Customer":
                return "customer";
            case "Rego":
                return "rego";
            case "Submitted":
                return "createdAt"
            default:
                return "createdAt";
        }
    }

    useEffect(() => {
        if (user === 'none' || profile === null) {
            history.push('/');
            return;
        }

        let orderItem = getOrderItem(selectedFilter)
        console.log("orderItem", orderItem);

        var ref = getDocketCollection(user, profile).orderBy(orderItem);
        var unsub = ref.onSnapshot(snapshot => {
            if (snapshot.size) {
                let list = [];
                snapshot.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setJobList(list);

            } else {
                console.log("Snapshot was empty");
            }
        });

        return () => {
            unsub();
        }

    }, [selectedFilter, history, profile, user]);

    const updateFilter = (txt) => {
        setSelectedFilter(txt);
    }

    const getVisibility = (item) => {
        if (selectedFilter !== 'Submitted') {
            if (item.submitted) return false;
            return true;
        } else {
            if (item.submitted) return true;
            return false;
        }
    }


    return (
        <div className="review-container">

            <NewDocket
                visible={addOverlay}
                close={toggleAddOverlay}
                edit={edit}
                paste={paste}
            />

            <Dropdown
                defaultValue={[fileterOptions[0]]}
                options={fileterOptions}
                onChange={updateFilter}
            />

            <div className="review-list-container">
                {jobList.map(item => {
                    if (getVisibility(item)) {
                        return (
                            <JobItem
                                key={item.id}
                                data={item}
                                edit={onEdit}
                                paste={onPaste}
                                remove={onDelete}
                            />
                        );
                    }

                    return null;

                })}
            </div>

            <div className="review-floating-btn" onClick={toggleAddOverlay}>
                <p>+</p>
            </div>

        </div>
    );
}

export default Review;