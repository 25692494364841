import React, { useState, useEffect } from 'react';
import '../css/LoadImageItem.css';
import ImageView from '../components/ImageView';

const LoadImageItem = ({ data, imageRemoval }) => {

    const [viewImage, setViewImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState();

    const toggleViewImage = () => {
        setViewImage(!viewImage);
    }

    const openImageViewer = (image) => {
        toggleViewImage();
        setSelectedImage(image);
    }

    const closeImageViewer = () => {
        setSelectedImage(undefined);
        toggleViewImage();
    }

    const onDelete = () => {
        imageRemoval(selectedImage);
        closeImageViewer();
    }

    return (
        <div className="loadI-container">
            <ImageView
                visible={viewImage}
                image={selectedImage}
                onClose={closeImageViewer}
                onDelete={onDelete}
            />
            <div className="loadI-header">
                <p>Documentation</p>
            </div>
            <div className="loadI-body">
                {data.imageUri.map(image => {
                    return (
                        <img src={image} key={data.createdAt.seconds} onClick={() => openImageViewer(image)} />
                    )
                })}
            </div>
        </div>
    );
}

export default LoadImageItem;