import React, { useState, useEffect } from 'react';
import '../css/Uploader.css';
import { getStorageRef, updateLoadImages } from '../api/Firebase';
import CustomButton from '../components/CustomButton';
import { userProfile, systemMessage, userToken } from '../State';
import { useRecoilState, useSetRecoilState } from 'recoil';

const Uploader = ({ visible, close, id }) => {
    const [image, setImage] = useState(null);
    const [filename, setFilename] = useState('Click here to select file');
    const [url, setUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);

    const [profile, setProfile] = useRecoilState(userProfile);
    const [user, setUser] = useRecoilState(userToken);
    const sysMsg = useSetRecoilState(systemMessage);

    const handleChange = e => {
        setError('');

        if (e.target.files[0]) {

            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (!validImageTypes.includes(e.target.files[0].type)) {
                setError('Please use valid image type .jpeg, .png');
                return;
            }
            setFilename(e.target.files[0].name);
            setImage(e.target.files[0]);
        }

    }

    const onClose = () => {
        setFilename('Click here to select file');
        close();
        setProgress(0);
        setUploading(false);
        setImage(null);
    }

    const handleUpload = () => {
        if (!image) return;
        setUploading(true);
        let ref = getStorageRef();
        let uploadTask = ref.child(`images/${profile.company}/${profile.name}/${image.name}`).put(image);

        uploadTask.on('state_changed',
            (snapshot) => {
                var p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(p);
            },
            (error) => {
                setError(error.code);
                console.log("upload error: ", error);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(uri => {
                    setUrl(uri);
                    handleUploadCompleted(uri);
                    console.log("image link: ", uri);
                });
            }
        );
    }

    const handleUploadCompleted = (uri) => {
        sysMsg('Upload complete: added to documentation');
        updateLoadImages(user, profile, id, uri, () => {
            onClose();
        });
    }

    return (
        <div className={visible ? "uploader-container" : "uploader-container hidden"}>
            <div className="uploader-card">
                <div className="uploader-header">
                    <h4>File Uploader</h4>
                </div>
                <div className="uploader-body">
                    <label htmlFor="file-upload" className="uploader-chooser">
                        <p>{filename}</p>
                        <div className="uploader-progress" style={{ width: `${progress}%` }} >
                        </div>
                    </label>
                    <input id="file-upload" type="file" onChange={handleChange} />
                </div>
                {!uploading ? <div className="uploader-footer">
                    <CustomButton className="uploader-button" onClick={onClose}>Cancel</CustomButton>
                    <CustomButton className="uploader-button" onClick={handleUpload}>Upload</CustomButton>
                </div> :
                    <div className="uploader-footer">
                        Uploading please wait...
                </div>
                }
            </div>
        </div >

    );
}

export default Uploader;