import React, { useState, useEffect } from 'react';
import '../css/SignUp.css';
import CustomButton from '../components/CustomButton';
import { useHistory } from 'react-router-dom';
import { createUser, getDB, createProfile } from '../api/Firebase';
import { userToken, systemMessage } from '../State';
import { useRecoilState } from 'recoil';
import { capitalFirstLetterWholeWord } from '../util/Strings';



const SignUp = () => {
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [companyCode, setCompanyCode] = useState('');
    const [error, setError] = useState('');

    const [globalList, setGlobalList] = useState([]);

    const [user, setUser] = useRecoilState(userToken);
    const [sysMsg, setSysMsg] = useRecoilState(systemMessage);
    let history = useHistory();

    useEffect(() => {
        getCompanyCodes();
    }, []);

    const signup = () => {
        if (!matchCode()) {
            setError("Please Enter Valid Company Code.");
            return
        }

        //validation
        if (validation() === 1) {
            setError("Please fill all fields");
            return;
        }
        if (validation() === 2) {
            setError("Both passwords must match");
            return;
        }

        //create the user
        createUser(username, password, (cred) => {
            setUser(cred.user);


            //some validation
            let validName = capitalFirstLetterWholeWord(name);

            const data = {
                name: validName,
                email: cred.user.email,
                company: companyCode,
                numberofdockets: 0
            };

            createProfile(data, cred.user.uid, () => {
                setSysMsg('User created successfully');
                history.push('/');
            });


        }, (error) => {
            console.log('creating user error: ', error);
            setError("Error::" + error.message);
        });
    }

    const updateName = e => {
        setName(e.target.value);
        setError('');
    }

    const updateUsername = e => {
        setUsername(e.target.value);
        setError('');
    }

    const updatePassword = e => {
        setPassword(e.target.value);
        setError('');
    }

    const updateConfirm = e => {
        setConfirm(e.target.value);
        setError('');
    }

    const updateCompanyCode = e => {
        setCompanyCode(e.target.value);
        setError('');
    }

    const validation = () => {
        if (name.trim() === '') return 1;
        if (username.trim() === '') return 1;
        if (password.trim() === '') return 1;
        if (confirm.trim() === '') return 1;
        if (companyCode.trim() === '') return 1;
        if (confirm !== password) return 2;

        return 0;
    }

    const getCompanyCodes = () => {
        var db = getDB();
        var ref = db.collection("public_globals").doc("companies");

        ref.get().then(doc => {
            if (doc.exists) {
                setGlobalList(doc.data().list);
            } else {
                setError("Global List not found.");
            }
        }).catch(error => {
            var s = 'Error fetching global list: \n';
            setError(s + error.toString());
        });
    }

    const matchCode = () => {
        if (companyCode === undefined) return false;

        const code = companyCode.toUpperCase();

        for (let i = 0; i < globalList.length; i++) {
            if (code === globalList[i].value) {
                return true;
            }
        }

        return false;
    }

    return (
        <div className="signup-container">
            <div className="signup-card">
                <div className="signup-card-header">
                    <h3>Sign Up</h3>
                </div>

                <div className="signup-card-main">
                    <label htmlFor="username">Name</label>
                    <input id="username" type="text" placeholder="" value={name} onChange={updateName} />
                    <label htmlFor="username">Email</label>
                    <input id="username" type="email" placeholder="" value={username} onChange={updateUsername} />
                    <label htmlFor="password" >Password</label>
                    <input id="password" type="password" placeholder="" value={password} onChange={updatePassword} />
                    <label htmlFor="confirm" >Confirm Password</label>
                    <input id="confirm" type="password" placeholder="" value={confirm} onChange={updateConfirm} />
                    <label htmlFor="username">Company Code</label>
                    <input id="username" type="text" placeholder="" value={companyCode} onChange={updateCompanyCode} />
                    <div className="signup-btn-container">
                        <CustomButton className="signup-button" width="50%" onClick={() => { history.push("/") }}>Back</CustomButton>
                        <CustomButton className="signup-button" width="50%" onClick={signup}>Sign Up</CustomButton>
                    </div>

                </div>

                <div className="signup-card-footer">
                    <p className="signup-error">{error}</p>
                </div>
            </div>
        </div>
    );
}

export default SignUp;