import React, { useState, useEffect } from 'react';
import '../css/LoadItem.css';
import { longDate } from '../util/Dates';
import { FaEdit, FaCopy, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const LoadItem = ({ data, edit, remove, paste }) => {
    let history = useHistory();

    const onEdit = () => {
        if (data.submitted) {
            alert("Item has already been submitted and cannot be changed");
            return;
        }
        edit(data);
    }

    const onRemove = () => {
        if (data.submitted) {
            alert("Item has already been submitted and cannot be changed");
            return;
        }
        remove(data.id);
    }

    const onPaste = () => {
        if (data.submitted) {
            alert("Item has already been submitted and cannot be changed");
            return;
        }
        paste(data);
    }


    return (
        <div className="li-container" >
            <div className="li-header">
                <FaEdit className={data.submitted ? "edit-icon submitted" : "edit-icon"} onClick={onEdit} />
                <p>{longDate(data.createdAt)}</p>
                {data.submitted ? <p className="submitted">submitted</p> : null}
                <FaCopy className={data.submitted ? "copy-icon submitted" : "copy-icon"} onClick={onPaste} />
                <FaTrash className={data.submitted ? "trash-icon submitted" : "trash-icon"} onClick={onRemove} />
            </div>

            <div className="li-body" >
                <table>
                    <tr>
                        <td className="li-left">
                            <p><span>Material: </span>{data.material}</p>
                            <p><span>From: </span>{data.fromLocation}</p>
                            <p><span>Pickup: </span>{data.pickupTime}</p>
                            <p><span>Docket #: </span>{data.docketNo}</p>
                            <p><span>Notes: </span>{data.notes}</p>
                        </td>
                        <td className="li-right">
                            <p></p>
                            <p><span>To: </span>{data.toLocation}</p>
                            <p><span>Drop Off: </span>{data.dropOffTime}</p>
                            <p><span>Quantity: </span>{data.quantity}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>);
}

export default LoadItem;
