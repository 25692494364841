import './App.css';
import Login from './screens/Login';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Navbar from './components/Navbar';
import SignUp from './screens/SignUp';
import Home from './screens/Home';
import { RecoilRoot } from 'recoil';
import Review from './screens/Review';
import Load from './screens/Load';
import Settings from './screens/Settings';
import ChangeEmail from './screens/Settings/ChangeEmail';
import SystemToast from './components/SystemToast';
import ChangePassword from './screens/Settings/ChangePassword';

function App() {

  return (
    <RecoilRoot>
      <Router >
        <div className="App" >
          <Navbar />
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/home" component={Home} />
            <Route path="/review" component={Review} />
            <Route path="/load/:id" component={Load} />
            <Route path="/settings" component={Settings} />
            <Route path="/changeEmail" component={ChangeEmail} />
            <Route path="/reset" component={ChangePassword} />
            <Route path="/forgot" component={ChangePassword} />
          </Switch>
          <SystemToast />
        </div>
      </Router>
    </RecoilRoot>
  );
}

export default App;
