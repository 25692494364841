import React, { useState } from 'react';
import '../css/Settings.css'
import { FaUserAlt, FaLock, FaBookmark, FaRProject } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { AiFillBank, AiFillInfoCircle } from 'react-icons/ai';
import { BiChevronRight } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';



const Settings = () => {

    let history = useHistory();

    const adminAlert = (field) => {
        alert(`Please contact your administrator or email support@makeiteasy.co.nz to have your ${field} changed.`);
    }
    return (
        <div className="settings-container">

            <div className="settings-item" onClick={() => adminAlert('Name')}>
                <FaUserAlt className="settings-item-icon" />
                <p>Change Name</p>
                <BiChevronRight className="settings-item-chevron" />
            </div>

            <div className="settings-item" onClick={() => history.push("/changeEmail")}>
                <MdEmail className="settings-item-icon" />
                <p>Change Email</p>
                <BiChevronRight className="settings-item-chevron" />
            </div>

            <div className="settings-item" onClick={() => history.push("/reset")}>
                <FaLock className="settings-item-icon" />
                <p>Change Password</p>
                <BiChevronRight className="settings-item-chevron" />
            </div>
            <div className="settings-item">
                <FaBookmark className="settings-item-icon" />
                <p>Change Line Manager</p>
                <BiChevronRight className="settings-item-chevron" />
            </div>
            <div className="settings-item" onClick={() => adminAlert('Company')}>
                <AiFillBank className="settings-item-icon" />
                <p>Change Company</p>
                <BiChevronRight className="settings-item-chevron" />
            </div>
            <a href="https://makeiteasy.co.nz/mieprivacypolicy" style={{ textDecoration: 'none' }} className="settings-item">
                <AiFillInfoCircle className="settings-item-icon" />
                <p>Privacy Policy</p>
                <BiChevronRight className="settings-item-chevron" />
            </a>
        </div>
    );
}

export default Settings;