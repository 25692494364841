import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import '../css/Navbar.css';
import { userToken } from '../State';
import { useRecoilState } from 'recoil';
import { FiMenu } from 'react-icons/fi';
import Sidebar from '../components/Sidebar';

const Navbar = () => {

    const [user, setUser] = useRecoilState(userToken);
    const [sidebar, setSidebar] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const toggleSidebar = () => {
        setSidebar(!sidebar);
    }

    useEffect(() => {
        if (user === 'none') {
            history.push('/');
        }
    }, []);

    let path = location.pathname;

    path = path.replace("/", "");
    path = path.charAt(0).toUpperCase() + path.slice(1);

    if (path.split("/").length > 1) {
        path = path.split("/")[0];
    }

    return (

        <div className={user !== 'none' ? "nav-container" : "nav-container hide"}>
            <p className="nav-item header">{path}</p>
            <FiMenu className="nav-item footer" onClick={toggleSidebar} />

            <Sidebar visible={sidebar} backClick={toggleSidebar} />
        </div>

    );
}

export default Navbar;