import React, { useState } from 'react';
import "../css/JobItem.css";
import { longDate } from '../util/Dates';
import { FaEdit, FaCopy, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const JobItem = ({ data, edit, remove, paste }) => {

    let history = useHistory();

    const onEdit = () => {
        if (data.submitted) {
            alert("Item has already been submitted and cannot be changed");
            return;
        }
        edit(data);
    }

    const onRemove = () => {
        if (data.submitted) {
            alert("Item has already been submitted and cannot be changed");
            return;
        }
        remove(data.id);
    }

    const onPaste = () => {
        if (data.submitted) {
            alert("Item has already been submitted and cannot be changed");
            return;
        }
        paste(data);
    }

    const navigateToLoad = () => {
        history.push('/load/' + data.id);
    }


    return (
        <div className="job-container" >
            <div className="job-header">
                <FaEdit className={data.submitted ? "edit-icon submitted" : "edit-icon"} onClick={onEdit} />
                <p>{longDate(data.createdAt)}</p>
                {data.submitted ? <p className="submitted">submitted</p> : null}
                <FaCopy className={data.submitted ? "copy-icon submitted" : "copy-icon"} onClick={onPaste} />
                <FaTrash className={data.submitted ? "trash-icon submitted" : "trash-icon"} onClick={onRemove} />
            </div>

            <div className="job-body" onClick={navigateToLoad}>
                <table>
                    <tr>
                        <td className="job-left">
                            <p><span>Driver: </span>{data.driver}</p>
                            <p><span>Working For: </span>{data.workingFor}</p>
                            <p><span>Rego: </span>{data.rego}</p>
                            {data.startTime ? <p><span>Start: </span>{data.startTime}</p> : null}
                        </td>
                        <td className="job-right">
                            <p><span>Customer: </span>{data.customer}</p>
                            <p><span>Order No.: </span>{data.orderNo}</p>
                            <p><span>Truck Type: </span>{data.truckType}</p>
                            {data.endTime ? <p><span>End: </span>{data.endTime}</p> : null}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default JobItem;