import React, { useState, useEffect } from 'react';
import "../css/Load.css";
import { useHistory, useParams } from 'react-router-dom';
import { userToken, userProfile, systemMessage } from '../State';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getDocket, getLoadCollection, updateDocket, getCompanyData, updateCompanyData, getIncrement, removeLoadImage } from '../api/Firebase';
import LoadItem from '../components/LoadItem';
import Footer from '../components/Footer';
import NewLoad from '../components/NewLoad';
import { compareTime } from '../util/Times';
import LoadImageItem from '../components/LoadImageItem';
import Uploader from '../components/Uploader';
import TravelTimeOverlay from '../components/TravelTimeOverlay';
import { sendEmail } from '../util/Emails';

const Load = () => {

    const [user, setUser] = useRecoilState(userToken);
    const [profile, setProfile] = useRecoilState(userProfile);
    const setMessage = useSetRecoilState(systemMessage);

    const [data, setData] = useState(null);
    const [loadlist, setLoadList] = useState([]);

    const [edit, setEdit] = useState();
    const [paste, setPaste] = useState();

    const [addOverlay, setAddOverlay] = useState(false);
    const [uploaderOverlay, setUploaderOverlay] = useState(false);
    const [travelOverlay, setTravelOverlay] = useState(false);

    const [loading, setLoading] = useState(true);

    let history = useHistory();
    let { id } = useParams();

    const onEdit = data => {
        setEdit(data);
        toggleAddOverlay();
    }

    const onPaste = data => {
        setPaste(data);
        toggleAddOverlay();
    }

    const onDelete = (JobID) => {
        let result = window.confirm("Are you sure you wish to delete docket: " + JobID + " ? This cannot be undone!");

        if (result) {
            let ref = getLoadCollection(user, profile, id).doc(JobID).delete();
            if (loadlist.length == 1) {
                setLoadList([]);
            }
            setMessage('Load Deleted');
        }


    }

    const toggleAddOverlay = () => {
        if (addOverlay) setEdit(null);
        if (addOverlay) setPaste(null);
        setAddOverlay(!addOverlay);
    }

    const toggleUploader = () => {
        setUploaderOverlay(!uploaderOverlay);
    }

    const toggleTravel = () => {
        setTravelOverlay(!travelOverlay);
    }

    const submitLoad = () => {
        toggleTravel();
    }

    const addJobTimes = () => {
        if (loadlist === undefined) return;
        var list = loadlist.filter(load => {
            if (!load) return false;
            if (load.imageUri === undefined || load.imageUri === null) {
                return true;
            } else {
                return false;
            }
        });

        var currentStart;
        var currentEnd;
        var changed = false;

        if (list.length <= 0 && !data.startTime) {
            //brand new docket detected
            // console.log("brand new docket");
            return;
        }

        if (list.length <= 0 && data.startTime) {
            //in event of load deleted & no dockets left
            currentStart = null;
            currentEnd = null;
            changed = true;
            // console.log("load deleted");
        } else {
            currentStart = data.startTime;
            currentEnd = data.endTime;
            // console.log("this was run");
        }


        // console.log("This is the job: ", data);
        if (list.length > 0) {
            list.forEach(load => {
                if (!currentStart) {
                    //first docket added
                    currentStart = load.pickupTime;
                    currentEnd = load.dropOffTime;
                    // console.log("first load added");
                    changed = true;
                    return;
                }

                //save earliest start time
                if (compareTime(currentStart, load.pickupTime) > 0) {
                    currentStart = load.pickupTime;
                    changed = true;
                    // console.log("smaller pickup found");
                }

                if (compareTime(currentEnd, load.dropOffTime) < 0) {
                    currentEnd = load.dropOffTime;
                    changed = true;
                    // console.log("larger dropOff found");
                }
            });

            // console.log("currentStart: ", currentStart);
            // console.log("currentEnd: ", currentEnd);

        }

        if (changed) {
            updateDocket(user, profile, id, { startTime: currentStart, endTime: currentEnd });
            getJobDetails();
            // console.log("times changed");
        }



    }

    const getJobDetails = () => {
        //Get the job details
        getDocket(user, profile, id).get().then(doc => {
            if (doc.exists) {
                setData(doc.data());
                // console.log("data found...", doc.data());
            } else {
                console.log('Job details not found');
            }
        }).catch(error => {
            console.log("Error getting document: " + error.code);
        });
    }

    //return an ordered loadlist with ascending pickuptime
    const getOrderLoadList = (list) => {
        let imageArray = [];
        let loadArray = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].imageUri) {
                imageArray.push(list[i]);
            } else {
                loadArray.push(list[i]);
            }
        }

        loadArray.sort((a, b) => {
            return compareTime(a.pickupTime, b.pickupTime);
        })

        loadArray.push(imageArray[0])
        return loadArray;

    }

    useEffect(() => {
        if (!user.uid || !profile.name) {
            //user is not logged in
            history.push('/');
            return;
        }

        getJobDetails();

        //Get the collection of loads
        var ref = getLoadCollection(user, profile, id).orderBy('createdAt');
        var unsub = ref.onSnapshot(snapshot => {
            if (snapshot.size) {
                let list = [];
                snapshot.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setLoadList(getOrderLoadList(list));
                setLoading(false);

            } else {
                console.log("Snapshot was empty");
            }
        });

        return () => {
            unsub();
        }


    }, []);

    useEffect(() => {
        if (data && !loading) {
            addJobTimes();
            // console.log("entry point");
        }

    }, [loadlist]);

    const uploadPhoto = () => {
        toggleUploader();
    }

    const handleRemoveImage = (image) => {
        let result = window.confirm("Are you sure you wish to delete this image? This cannot be undone!");

        if (result) {
            let ref = removeLoadImage(user, profile, id, image, () => {
                setMessage('Image Deleted');
            });


        }
    }

    const submit = (travelTime) => {
        //generate a serial number
        getCompanyData(profile.companyCode, result => {
            let num = 1000 + result.numberofdockets + 1;
            let serial = result.prefix + '' + num;

            updateDocket(user, profile, id, { travelTime: travelTime, submitted: true, serial: serial });
            updateCompanyData(profile.companyCode, { numberofdockets: getIncrement() });
            sendEmail(loadlist, data, profile, travelTime, serial, (msg) => {
                setMessage(msg);
                history.push('/review');
            });

        }, error => {
            console.log(error);
            setMessage('Error retrieving company data');
        })
    }

    return (
        <div className="load-container">

            <NewLoad
                visible={addOverlay}
                close={toggleAddOverlay}
                edit={edit}
                paste={paste}
                id={id}
            />

            <Uploader
                visible={uploaderOverlay}
                close={toggleUploader}
                id={id}
            />

            <TravelTimeOverlay
                visible={travelOverlay}
                onClose={toggleTravel}
                onSubmit={submit}
            />

            <div className="load-header">
                <h3>Job Details</h3>
                <table>
                    <tbody>
                        <tr>
                            <td className="load-left"><p>Driver: <span>{data && data.driver}</span></p></td>
                            <td><p>Customer: <span>{data && data.customer}</span></p></td>
                        </tr>
                        <tr>
                            <td className="load-left"><p>Working For: <span>{data && data.workingFor}</span></p></td>
                            <td><p>Order No: <span>{data && data.orderNo}</span></p></td>
                        </tr>
                        <tr>
                            <td className="load-left"><p>Rego: <span>{data && data.rego}</span></p></td>
                            <td><p>Truck Type: <span>{data && data.truckType}</span></p></td>
                        </tr>
                        <tr>
                            <td className="load-left"><p>Start: <span>{data && data.startTime}</span></p></td>
                            <td><p>End: <span>{data && data.endTime}</span></p></td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div className="load-list-container">
                {loadlist.map(item => {
                    if (!item) return;
                    if (!item.imageUri) {
                        return (
                            <LoadItem
                                key={item.id}
                                data={item}
                                edit={onEdit}
                                paste={onPaste}
                                remove={onDelete}
                            />
                        );
                    } else {
                        return (
                            <LoadImageItem
                                key={item.id}
                                data={item}
                                imageRemoval={handleRemoveImage}
                            />
                        );
                    }


                })}
            </div>


            <Footer
                onAdd={toggleAddOverlay}
                onPhoto={uploadPhoto}
                onSubmit={submitLoad}
            />

        </div>
    );
}

export default Load;