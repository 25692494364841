import React, { useState, useEffect } from 'react';
import '../css/Home.css';
import { useRecoilState } from 'recoil';
import { userProfile } from '../State';
import CustomButton from '../components/CustomButton';
import NewDocket from '../components/NewDocket';
import { useHistory } from 'react-router-dom';

const Home = () => {

    const [profile, setProfile] = useRecoilState(userProfile);

    const [addOverlay, setAddOverlay] = useState(false);

    let history = useHistory();

    const gotoReview = () => {
        history.push('/review');
    }

    const toggleAddOverlay = () => {
        setAddOverlay(!addOverlay);
    }

    useEffect(() => {
        if (!profile) {
            history.push('/');
        }
    })


    return (
        <div className="home-container">

            <NewDocket
                visible={addOverlay}
                close={toggleAddOverlay}
            />

            <div className="home-card">
                <div className="home-card-header">
                    <h1>Welcome</h1>
                    <span>{profile && profile.name}</span>
                </div>

                <div className="home-card-main">
                    <span className="home-card-title">Email:</span>
                    <span className="home-card-info">{profile && profile.email}</span>

                    <span className="home-card-title">Line Manager Email:</span>
                    <span className="home-card-info">{profile && profile.linemanager}</span>

                    <span className="home-card-title">Company:</span>
                    <span className="home-card-info">{profile && profile.company}</span>
                </div>

                <img src="/images/logo.png" alt="e-icon" />
            </div>

            <div className="home-buttons">
                <CustomButton className="home-btn" width="50%" onClick={gotoReview}>Review Jobs</CustomButton>
                <CustomButton className="home-btn" width="50%" onClick={toggleAddOverlay}>Add New Jobs</CustomButton>
            </div>
        </div>
    );
}

export default Home;