import React, { useState } from 'react';

const CustomDropdown = ({ className, defaultValue, options, onChange, id, labelTitle }) => {

    const [selected, setSelected] = useState(defaultValue);

    const updateSelected = (event) => {
        setSelected(event.target.value);
        onChange(event.target.value);
    }

    return (
        <div className={className}>
            {id && <label htmlFor={id}>
                {labelTitle}
            </label>}
            <select id={id} onChange={updateSelected} value={selected}>
                {options && options.map(op => {
                    return (
                        <option key={op} value={op}>{op}</option>
                    )
                })}
            </select>
        </div>
    );
}

export default CustomDropdown;