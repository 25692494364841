import React, { useState, useEffect } from 'react';
import "../css/NewLoad.css";
import CustomButton from '../components/CustomButton';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { userToken, userProfile, systemMessage } from '../State';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { capitalFirstLetterWholeWord } from '../util/Strings';
import { addNewLoad, updateLoad } from '../api/Firebase';
import Timepicker from '../components/Timepicker';
import { compareTime } from '../util/Times';

const NewLoad = ({ visible, close, edit, paste, id }) => {

    const [user, setUser] = useRecoilState(userToken);
    const [profile, setProfile] = useRecoilState(userProfile);
    const setMessage = useSetRecoilState(systemMessage);

    const [material, setMaterial] = useState("");
    const [fromLocation, setFromLocation] = useState("");
    const [toLocation, setToLocation] = useState("");
    const [docketNo, setDocketNo] = useState("");
    const [quantity, setQuantity] = useState("");
    const [pickupTime, setPickupTime] = useState("");
    const [dropOffTime, setDropOffTime] = useState();
    const [notes, setNotes] = useState("");
    const [timeError, setTimeError] = useState("");

    const add = () => {
        //validate the time
        if (!validateTime()) {
            setTimeError("Drop off needs to be after pickup.");
            console.log("pickup: ", pickupTime);
            console.log("dropoff: ", dropOffTime);
            return;
        }

        //some input validation
        let validMaterial = capitalFirstLetterWholeWord(material);
        let validFrom = capitalFirstLetterWholeWord(fromLocation);
        let validTo = capitalFirstLetterWholeWord(toLocation);

        let data = {
            material: validMaterial,
            fromLocation: validFrom,
            toLocation: validTo,
            docketNo: docketNo,
            quantity: quantity,
            pickupTime: pickupTime,
            dropOffTime: dropOffTime,
            notes: notes
        }

        addNewLoad(user, profile, id, data).then(result => {
            onClose();
            setMessage("Loaded added successfully");
            //Navigate to specific docket
        });
    }

    const onClose = () => {
        resetInput();
        close();
    }

    const save = () => {
        //validate the time
        if (!validateTime()) {
            setTimeError("Drop off needs to be after pickup.");
            console.log("pickup: ", pickupTime);
            console.log("dropoff: ", dropOffTime);
            return;
        }

        //some input validation
        let validMaterial = capitalFirstLetterWholeWord(material);
        let validFrom = capitalFirstLetterWholeWord(fromLocation);
        let validTo = capitalFirstLetterWholeWord(toLocation);

        let data = {
            material: validMaterial,
            fromLocation: validFrom,
            toLocation: validTo,
            docketNo: docketNo,
            quantity: quantity,
            pickupTime: pickupTime,
            dropOffTime: dropOffTime,
            notes: notes
        }

        updateLoad(user, profile, edit.id, id, data).then(result => {
            onClose();
            setMessage("Load update successful");
        }).catch(error => {
            alert("Error: ", error.message);
            console.log("Error: ", error);
            console.log("id: " + edit.id);

        });


    }

    const validateTime = () => {
        if (pickupTime === "" || dropOffTime === "" || pickupTime === undefined || dropOffTime === undefined) {
            return false;
        }
        if (compareTime(pickupTime, dropOffTime) >= 0) {
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (edit) {
            setUpEdit();
        }
        if (paste) {
            setUpPaste();
        }
    }, [edit, paste]);



    const updateMaterial = e => {
        setMaterial(e.target.value);
    }
    const updateFromLocation = e => {
        setFromLocation(e.target.value);
    }
    const updateToLocation = e => {
        setToLocation(e.target.value);
    }
    const updateDocketNo = e => {
        setDocketNo(e.target.value);
    }
    const updateQuantity = e => {
        setQuantity(e.target.value);
    }
    const updateNotes = e => {
        setNotes(e.target.value);
    }

    const updatePickup = t => {
        setPickupTime(t);
        setTimeError("");
    }

    const updateDropOff = t => {
        setDropOffTime(t);
        setTimeError("");
    }

    const resetInput = () => {
        setMaterial("");
        setFromLocation("");
        setToLocation("");
        setDocketNo("");
        setQuantity("");
        setPickupTime("");
        setDropOffTime("");
        setNotes("");
    }

    const setUpEdit = () => {
        setMaterial(edit.material);
        setFromLocation(edit.fromLocation);
        setToLocation(edit.toLocation);
        setDocketNo(edit.docketNo);
        setQuantity(edit.quantity);
        setPickupTime(edit.pickupTime);
        setDropOffTime(edit.dropOffTime);
        setNotes(edit.notes);
    }

    const setUpPaste = () => {
        setMaterial(paste.material);
        setFromLocation(paste.fromLocation);
        setToLocation(paste.toLocation);
        setDocketNo(paste.docketNo);
        setQuantity(paste.quantity);
        setPickupTime(paste.pickupTime);
        setDropOffTime(paste.dropOffTime);
        setNotes(paste.notes);
    }


    return (
        <div className={visible ? "ND-container" : "ND-container hide"}>
            <div className="ND-card">
                <div className="ND-card-header">
                    {!edit ? <h4>Create Job</h4> : <h4>Edit Job</h4>}
                    <AiOutlineCloseCircle className="ND-icon" onClick={onClose} />
                </div>
                <div className="ND-card-body">
                    <label>Material</label>
                    <input type="text" placeholder="Material" value={material} onChange={updateMaterial} />
                    <label>From Location</label>
                    <input type="text" placeholder="From ..." value={fromLocation} onChange={updateFromLocation} />
                    <label>To Location</label>
                    <input type="text" placeholder="To ..." value={toLocation} onChange={updateToLocation} />
                    <label>Docket #</label>
                    <input type="text" placeholder="Docket Number" value={docketNo} onChange={updateDocketNo} />
                    <label>Quantity</label>
                    <input type="text" placeholder="Quantity" value={quantity} onChange={updateQuantity} />
                    <label>Notes</label>
                    <input type="text" placeholder="Notes" value={notes} onChange={updateNotes} />
                    <Timepicker
                        value={pickupTime}
                        label="Pickup Time"
                        onChange={updatePickup}
                    />
                    {timeError !== "" ? <div className="ND-error">
                        {timeError}
                    </div> : null}
                    <Timepicker
                        value={dropOffTime}
                        label="Drop Off Time"
                        onChange={updateDropOff}
                    />
                </div>
                {!edit ? <CustomButton className="ND-btn" width="100%" onClick={add}>Add</CustomButton> : null}
                {edit ? <CustomButton className="ND-btn" width="100%" onClick={save}>Save</CustomButton> : null}
            </div>
        </div>
    );
}

export default NewLoad;