import React, { useState } from 'react';
import '../css/Footer.css';
import { FiCamera } from 'react-icons/fi';
import { BsArchive } from 'react-icons/bs';
import { IoIosAddCircleOutline } from 'react-icons/io';


const Footer = ({ onAdd, onPhoto, onSubmit }) => {

    const submitClicked = () => {
        let result = window.confirm('Are you sure you would like to submit? Please note that you will be unable to make any changes to this job.');

        if (result) {
            onSubmit();
        }
    }

    return (
        <div className="footer-container">
            <div className="footer-icon-container" onClick={submitClicked}>
                <BsArchive className="footer-submit" />
                <p>Submit</p>
            </div>

            <div className="footer-icon-container" onClick={onPhoto}>
                <FiCamera className="footer-camera" />
                <p>Photo</p>
            </div>

            <div className="footer-icon-container" onClick={onAdd}>
                <IoIosAddCircleOutline className="footer-add" />
                <p>Add</p>
            </div>
        </div>
    );
}

export default Footer;